/**
 * Job list Template
 */
/* Import section */
import { graphql } from "gatsby"
import React from "react"
import { Link, I18nextContext } from "gatsby-plugin-react-i18next"
import { IconContext } from "react-icons"
import { BsArrowRight } from "react-icons/bs"
import Blockcareer from "../components/blockcareer"
import Blockbenefit from "../components/blockbenefit"
import Herotype2 from "../components/herotype2"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Slidertestimonial from "../components/slidertestimonial"
import "../styles/templates/joblist.scss"


/* Declaration class */
const JobListTemplate = ({ data }) => {

  //Const data page
  const dataPage = data.wpPage
  const dataJob = data.allWpJob.nodes
  const context = React.useContext(I18nextContext)
  var cLangSlug = context.language
  var cLang = (cLangSlug === "en") ? "En" : "Fr"

  var rootLink = (dataPage.language.slug === "en") ? "/careers/": "/carrieres/"
  var rootLinkApply = (dataPage.language.slug === "en") ? "/careers/apply": "/carrieres/postuler"
  var tLinkSlug = (dataPage.language.slug === "en") ? "/carrieres": "/careers"
  var ctaCareer = (dataPage.language.slug === "en") ? "Apply": "Postuler"

  var cAcf = "acfPageJobs"+cLang
  var cTitleH1 = "titleH1Jobs"+cLang
  var cTitleH2intro = "titleH2Jobs"+cLang
  var cTextIntro = "textIntroJobs"+cLang
  var cTitleH2Benefits = "titleH2JobsBenefits"+cLang
  var cTextBenefits = "textH2JobsBenefits"+cLang
  var cRepeaterBlockJobs = "repeaterBlockJobs"+cLang
  var cRepeaterBenefitsJobs = "repeaterBenefitsJobs"+cLang
  var cRepeaterTestimonials = "repeaterTestimonials"+cLang
  const dataAcf = dataPage[cAcf]
  const titleH1 = dataAcf[cTitleH1]
  const titleH2intro = dataAcf[cTitleH2intro]
  const textIntro = dataAcf[cTextIntro]
  const titleH2Benefits = dataAcf[cTitleH2Benefits]
  const textBenefits = dataAcf[cTextBenefits]
  const repeaterBlockJobs = dataAcf[cRepeaterBlockJobs]
  const repeaterBenefitJobs = dataAcf[cRepeaterBenefitsJobs]
  const repeaterTestimonials = dataAcf[cRepeaterTestimonials]

  return (
    <Layout tLinkSlug={tLinkSlug}>
      <Seo
        title={dataPage.seo.title}
        description={dataPage.seo.metaDesc}
        tLinkSlug={tLinkSlug}
        slickslider="slick" />

      <section className="section-hero-jobs section-herotype2">
        <Herotype2 h1title={titleH1} classImg="job" />
      </section>

      <section className="section-benefits">
        <div className="wrap-maincontent">
          <div className="maincontent">
            <h2>{titleH2Benefits}</h2>
            <div className="text-maincentercontent" dangerouslySetInnerHTML={{ __html:textBenefits}} />
            <div className="box-blockbenefits">
              {repeaterBenefitJobs.map((benefitjob, index) =>
                <Blockbenefit
                  keyBenefit={index}
                  titleBenefit={benefitjob.titleBenefitJob}
                  textBenefit={benefitjob.textBenefitJob}
                  iconBenefit={benefitjob.iconBenefitJob.localFile.childImageSharp.gatsbyImageData}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      <section className="section-testimonial">
        <div className="wrap-maincontent">
          <div className="box-slider">
            <Slidertestimonial arrTestimonial={repeaterTestimonials} />
          </div>
        </div>
      </section>

      <section className="section-joblist">
        <div className="wrap-maincontent wrap-joblist">
          <div className="maincontent">
            <h2>{titleH2intro}</h2>
            <div className="text-maincentercontent" dangerouslySetInnerHTML={{ __html:textIntro}} />
            <div className="box-joblist">
              <ul>
                {dataJob.map(( node ) => (
                  <li>
                    <Link to={rootLink+node.slug} language={dataPage.language.slug}>
                      <span className="wrap-spanlink">
                        <span className="span-label">{node.acfCptJob.titleJob}</span>
                        <span className="span-arrow">
                          <IconContext.Provider value={{ className: "icon-arrow" }}>
                            <BsArrowRight />
                          </IconContext.Provider>
                        </span>
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="box-blockjob">
              {repeaterBlockJobs.map((blockjob, index) =>
                <Blockcareer
                  keyCareer={index}
                  titleCareer={blockjob.titleBlockJob}
                  textCareer={blockjob.textBlockJob}
                  iconCareer={blockjob.iconBlockJob.localFile.childImageSharp.gatsbyImageData}
                  linkParamCareer={rootLinkApply+"?job="+blockjob.paramLinkBlockJob}
                  ctaCareer={ctaCareer}
                />
              )}
            </div>
          </div>
        </div>
      </section>




    </Layout>
  )
}

/* Export template informations */
export default JobListTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allWpJob(filter: {language: {slug: {eq: $language}}}, sort: {order: ASC, fields: acfCptJob___titleJob}) {
      nodes {
        acfCptJob {
          titleJob
        }
        language {
          slug
        }
        slug
      }
    }
    wpPage(slug: {regex: "/jobs/"}, language: {slug: {eq: $language}}) {
      acfPageJobsEn {
        titleH1JobsEn
        titleH2JobsEn
        textIntroJobsEn
        repeaterBlockJobsEn {
          titleBlockJob
          textBlockJob
          paramLinkBlockJob
          iconBlockJob {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
        }
        titleH2JobsBenefitsEn
        textH2JobsBenefitsEn
        repeaterBenefitsJobsEn {
          titleBenefitJob
          textBenefitJob
          iconBenefitJob {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
        }
        repeaterTestimonialsEn {
          textTestimonial
        }
      }
      acfPageJobsFr {
        titleH1JobsFr
        titleH2JobsFr
        textIntroJobsFr
        repeaterBlockJobsFr {
          titleBlockJob
          textBlockJob
          paramLinkBlockJob
          iconBlockJob {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
        }
        titleH2JobsBenefitsFr
        textH2JobsBenefitsFr
        repeaterBenefitsJobsFr {
          titleBenefitJob
          textBenefitJob
          iconBenefitJob {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, formats: [AUTO, WEBP], width: 200)
              }
            }
          }
        }
        repeaterTestimonialsFr {
          textTestimonial
        }
      }
      language {
        slug
      }
      seo {
        metaDesc
        title
      }
    }
  }
`
