/**
 * Const with params element
 * Slider hero component
 * Display a slider of mulitple text with Slick
 */
/* Import section */
import React from "react"
import { LazyLoad } from "react-observer-api"
import Slider from "react-slick"
import "../styles/common/slider.scss"

/* Declaration function */
const Slidertestimonial = ( { keySlider, arrTestimonial } ) => {
  const settings = {
    arrows: false,
    autoplay: true,
    dots: true,
    fade: false,
    infinite: true,
    lazyLoad: true,
    slidesToScroll: 1,
    slidesToShow: 1,
    autoplaySpeed: 5000,
    swipe: true,
  }

  return (
    <LazyLoad>
      <Slider key={keySlider} className="slider slider-testimonial" {...settings} >
        {arrTestimonial.map( (testimonial, index )  => (
          <div key={index} id={index} className="citation centercontent">
            <blockquote>{testimonial.textTestimonial}</blockquote>
          </div>
          )
        )}
      </Slider>
    </LazyLoad>

  )
}

/* Export function */
export default Slidertestimonial

