/**
 * Const with params element
 * Block benefit component
 * Display a block of benefit
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "../styles/components/blockbenefit.scss"


/* Declaration function */
const Blockbenefit = ( { keyBenefit, titleBenefit, textBenefit, iconBenefit} ) => {
  return (
    <div key={keyBenefit} className="block-benefit">
      <div className="wrap-flex-benefit">
        <div className="box-titlebenefit">
          <GatsbyImage image={iconBenefit} alt={`benefit: ${titleBenefit}`} />
          <h3 dangerouslySetInnerHTML={{__html:titleBenefit}} />
        </div>
        <div className="box-textbenefit" dangerouslySetInnerHTML={{__html:textBenefit}} />
      </div>
    </div>
  )
}

/* Export function */
export default Blockbenefit
