/**
 * Const with params element
 * Block Career component
 * Display a block of Career
 */
/* Import section */
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import "../styles/components/blockcareer.scss"
import Ctabutton from "./ctabutton"

/* Declaration function */
const Blockcareer = ( { keyCareer, titleCareer, textCareer, iconCareer, linkParamCareer, ctaCareer} ) => {
  return (
    <div key={keyCareer} className="block-career">
      <GatsbyImage image={iconCareer} alt={`Myca Career: ${titleCareer}`} />
      <div className="box-title-career">
        <h3>{titleCareer}</h3>
        <div dangerouslySetInnerHTML={{__html:textCareer}} />
        <Ctabutton ctaLink={linkParamCareer} ctaLabel={ctaCareer} />
      </div>
    </div>
  )
}

/* Export function */
export default Blockcareer
